<template>
  <userTemplate v-loading="loading">
    <div class="bg-danger font-28 pd-x-5">
      <el-row>
        <el-col
          :xs="{ span: 12, offset: 0 }"
          :sm="{ span: 8, offset: 4 }"
          :lg="{ span: 4, offset: 8 }"
        >
          <div>
            <p class="mg-y-6 text-white">{{ user.name }}</p>
          </div>
        </el-col>
        <el-col :xs="{ span: 12 }" :sm="{ span: 8 }" :lg="{ span: 4 }">
          <div>
            <p class="mg-y-6 text-white text-right">
              ข้อที่ {{ step.qId }}/{{ allExam.length }}
            </p>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-row
      class="pd-x-5 text-white text-center"
      :class="
        correct && userList != null
          ? 'bg-success'
          : userList && userList.createdAt == 0
          ? 'bg-warning'
          : 'bg-danger'
      "
    >
      <el-col
        :xs="{ span: 24, offset: 0 }"
        :sm="{ span: 16, offset: 4 }"
        :lg="{ span: 8, offset: 8 }"
        class="h-100 min-height-screen-235 is-flex ai-center js-center"
      >
        <div>
          <div v-if="userList && userList.createdAt != 0">
            <i
              v-if="correct"
              style="font-size: 72px"
              class="fas fa-check-circle"
            ></i>
            <i v-else style="font-size: 72px" class="fas fa-times-circle"></i>
          </div>
          <div v-if="userList && userList.createdAt != 0">
            <h1>{{ correct ? "ถูกต้อง!" : "ผิด!" }}</h1>
          </div>
          <div class="badge-score">
            <h2 class="mg-y-6">
              <span v-if="correct && userList && userList.createdAt != 0">{{
                userList.isPoint | comma
              }}</span>
              <span v-else class="font-24">คุณลองพยายามใหม่ในข้อต่อไป</span>
            </h2>
          </div>
          <div>
            <h2 v-if="userList != null">คุณได้ลำดับที่ {{ range }}</h2>
          </div>
          <div class="mg-t-1">
            <h3>
              คะแนนรวม
              <span v-if="userList != null"
                >{{ userList.point | comma }} คะแนน</span
              >
              <span v-else>0 คะแนน</span>
            </h3>
          </div>
          <div class="mg-t-1" v-if="step.qId == 16 && step.state == 'rank'">
            <el-button type="primary" @click="$router.push('/total-score')"
              >ดูผลลัพธ์</el-button
            >
          </div>
        </div>
      </el-col>
    </el-row>
  </userTemplate>
</template>
<script>
import { HTTP } from "@/service/axios.js";
import userTemplate from "@/template/VoteUserTemplate";
export default {
  methods: {
    getRang() {
      let obj = {
        roomId: this.room.roomId,
        qId: this.step.qId != "undefined" ? this.step.qId : this.step.qid
      };
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.post("rating", obj).then(res => {
        // console.log("rating", res);
        if (res.data.success) {
          let sort = res.data.obj.sort((a, b) => {
            let modifier = -1;
            if (a.point < b.point) return -1 * modifier;
            if (a.point > b.point) return 1 * modifier;
            return 0;
          });
          let index = sort.findIndex(a => a.id == this.user.id);
          this.range = index + 1;
          let user = res.data.obj.filter(a => a.id == this.user.id);
          if (user.length > 0) {
            this.userList = user[0];
            this.userList.isPoint == 0
              ? (this.correct = false)
              : (this.correct = true);
          }
        }
      });
    },
    next() {
      this.$router.push("/total-score");
    }
  },
  components: {
    userTemplate
  },
  data() {
    return {
      range: 0,
      loading: true,
      correct: true,
      userList: null
    };
  },
  beforeDestroy() {
    clearInterval(this.intervalation);
  },
  async mounted() {
    this.getRang();
    this.getState();
    if (this.step.state != "answer") {
      await this.getStateNow();
    }
    this.intervalation = setInterval(() => {
      this.getState();
    }, 1000);
    setTimeout(() => {
      this.loading = false;
    }, 1500);
  },
  watch: {
    step() {
      if (this.step.state != "answer") {
        this.next();
      }
    }
  }
};
</script>